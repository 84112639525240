import { v4 as uuidv4 } from 'uuid';

import client from 'api/httpClient';

class SignalsController {
  constructor() {
    this.baseUrl = '/signals';
  }

  parseItem = signal => ({
    ...signal.content,
    timestamp: signal.providedTimestamp,
    id: uuidv4(),
  });

  fetchSignals = async (page, numberPerPage, symbols) => {
    try {
      const params = {
        page,
        per_page: numberPerPage,
      };

      if (symbols && symbols.length) {
        params.stocks = symbols.join();
      }

      const { data } = await client.get(this.baseUrl, { params });

      return data.map(this.parseItem);
    } catch {
      return [];
    }
  };
}

export default new SignalsController();
