import createReducer from 'utils/createReducer';
import { actionTypes } from 'actions/signals';
import { actionTypes as symbolsActionTypes } from 'actions/symbols';

const initialState = {
  realTime: [],
  query: {
    page: 1,
    hasMoreSignals: true,
    signals: [],
  },
};

const fetchSignalsSuccess = (state, action) => ({
  ...state,
  query: {
    signals: [...state.query.signals, ...action.signals],
    page: action.page,
    hasMoreSignals: action.hasMoreSignals,
  },
});

const addRealtimeSignal = (state, action) => ({
  ...state,
  realTime: [action.signal, ...state.realTime],
});

const setSelectedSymbols = state => ({
  ...state,
  query: initialState.query,
});

const handlers = {
  [actionTypes.FETCH_SIGNALS]: fetchSignalsSuccess,
  [actionTypes.ADD_REALTIME_SIGNAL]: addRealtimeSignal,
  [symbolsActionTypes.SET_SELECTED_SYMBOLS]: setSelectedSymbols,
};

export default createReducer(initialState, handlers);
