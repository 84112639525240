import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import notificationAudio from 'assets/notification.mp3';

import useAudio from './useAudio';
import { setSoundLastPlayed } from 'actions/ui';

const useNotificationAudio = (minElapsedTime = 30) => {
  const dispatch = useDispatch();
  const { play: playAudio, stop } = useAudio(notificationAudio);

  const soundEnabled = useSelector(state => state.ui.sound.enabled);
  const lastPlayed = useSelector(state => state.ui.sound.lastPlayed);

  const play = useCallback(() => {
    if (!soundEnabled) return;
    if (
      lastPlayed &&
      dayjs().diff(dayjs(lastPlayed), 'second') < minElapsedTime
    )
      return;

    dispatch(setSoundLastPlayed(dayjs().valueOf()));
    playAudio();
  }, [dispatch, playAudio, soundEnabled, lastPlayed, minElapsedTime]);

  return { play, stop };
};

export default useNotificationAudio;
