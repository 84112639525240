import client from 'api/httpClient';

const SYMBOL_URLS = {
  screener: '/stocks',
  signals: '/signals/symbols',
  news: '/news/symbols',
};

const fetchSymbols = async (url, mapper) => {
  try {
    const { data } = await client.get(url);
    return data.map(mapper).filter(symbol => !!symbol);
  } catch {
    return [];
  }
};

class SymbolsController {
  fetchStockSymbols = () =>
    fetchSymbols(SYMBOL_URLS.screener, stock => stock.symbol);

  fetchSignalsSymbols = () => fetchSymbols(SYMBOL_URLS.signals, stock => stock);

  fetchNewsSymbols = () => fetchSymbols(SYMBOL_URLS.news, stock => stock);
}

export default new SymbolsController();
