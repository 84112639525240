import createReducer from 'utils/createReducer';
import { actionTypes } from 'actions/symbols';

const initialState = {
  symbols: [],
  selectedSymbols: [],
};

const fetchSymbolSuccess = (state, action) => ({
  ...state,
  symbols: action.symbols,
});

const setSelectedSymbols = (state, action) => ({
  ...state,
  selectedSymbols: action.symbols,
});

const handlers = {
  [actionTypes.FETCH_SYMBOLS]: fetchSymbolSuccess,
  [actionTypes.SET_SELECTED_SYMBOLS]: setSelectedSymbols,
};

export default createReducer(initialState, handlers);
