import { useEffect } from 'react';

const useReachedBottom = (offset, onReachedBottom) => {
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - offset
      ) {
        onReachedBottom();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [offset, onReachedBottom]);
};

export default useReachedBottom;
