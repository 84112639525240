import React, { useEffect, useState, useMemo, useCallback } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Signals.module.css';

import Header from 'components/Header';
import Table from 'components/Table';
import SoundButton from 'components/SoundButton';
import SymbolTagInput from 'components/SymbolTagInput';
import useReachedBottom from 'hooks/useReachedBottom';
import useRealtimeInfo from 'hooks/useRealtimeInfo';
import { fetchSignalsSymbols } from 'actions/symbols';
import { fetchSignals, addRealtimeSignal } from 'actions/signals';

const LOGO_URL =
  'https://www.transparenttradersblackbox.com/images/cube_icons/activity-cube.png';
const CHANNEL_NAME = 'SignalsChannel';
const BOTTOM_OFFSET = 100;
const DATE_FORMAT = 'MMM DD, YYYY HH:mm';
const COLUMNS = [
  { label: 'Timestamp', key: 'timestamp' },
  { label: 'Stock', key: 'stock' },
  { label: 'Description', key: 'description' },
];
const SENTIMENT = {
  BEARISH: styles.tableStockRed,
  BULLISH: styles.tableStockGreen,
};

const renderCell = (columnKey, item) => {
  const renderer = {
    timestamp: () => dayjs(item.timestamp).format(DATE_FORMAT),
    stock: () => {
      const sentimentClass = SENTIMENT[item.sentiment];
      return sentimentClass ? (
        <span className={sentimentClass}>{item.ticker}</span>
      ) : (
        <>{item.ticker}</>
      );
    },
    description: () => item.description,
  };

  return renderer[columnKey]();
};

const Signals = () => {
  const dispatch = useDispatch();

  const symbols = useSelector(state => state.symbols.selectedSymbols);
  const querySignals = useSelector(state => state.signals.query.signals);
  const realTimeSignals = useSelector(state => state.signals.realTime);

  const { isLoadingSymbols } = useLoadSymbols();
  useLoadSignals(!isLoadingSymbols);

  const onMessageReceived = useCallback(
    message => {
      dispatch(addRealtimeSignal({ signal: message.json }));
    },
    [dispatch],
  );
  useRealtimeInfo({
    channel: CHANNEL_NAME,
    onMessageReceived,
  });

  const signals = useMemo(() => {
    const filteredRealTimeSignals =
      !symbols || !symbols.length
        ? realTimeSignals
        : realTimeSignals.filter(entry => symbols.includes(entry.ticker));
    return [...filteredRealTimeSignals, ...querySignals];
  }, [symbols, realTimeSignals, querySignals]);

  return (
    <div className={styles.container}>
      <Header logoUrl={LOGO_URL} title="Option Activity" />
      <div className={styles.content}>
        <div className={styles.optionsContainer}>
          <SymbolTagInput url="signals" />
          <SoundButton />
        </div>
        <div className={styles.tableContainer}>
          <Table
            className={styles.table}
            columnClasses={{
              timestamp: styles.tableTimestampColumn,
              stock: styles.tableStockColumn,
              description: styles.tableDescriptionColumn,
            }}
            items={signals}
            columns={COLUMNS}
            renderCell={renderCell}
          />
        </div>
      </div>
    </div>
  );
};

const useLoadSymbols = () => {
  const dispatch = useDispatch();
  const [isLoadingSymbols, setIsLoadingSymbols] = useState(true);

  useEffect(() => {
    let ignore = false;

    const loadSymbols = async () => {
      setIsLoadingSymbols(true);

      await dispatch(fetchSignalsSymbols());

      if (!ignore) {
        setIsLoadingSymbols(false);
      }
    };

    loadSymbols();

    return () => (ignore = true);
  }, [dispatch]);

  return { isLoadingSymbols };
};

const useLoadSignals = shouldFetch => {
  const dispatch = useDispatch();
  const [isLoadingSignals, setIsLoadingSignals] = useState(true);

  const symbols = useSelector(state => state.symbols.selectedSymbols);
  const hasMoreSignals = useSelector(
    state => state.signals.query.hasMoreSignals,
  );

  const loadSignals = useCallback(async () => {
    if (!hasMoreSignals) return;
    setIsLoadingSignals(true);
    await dispatch(fetchSignals());
  }, [hasMoreSignals, dispatch]);

  // load next signals page when reached bottom
  const handleReachedBottom = useCallback(async () => {
    if (isLoadingSignals) return;
    await loadSignals();
    setIsLoadingSignals(false);
  }, [isLoadingSignals, loadSignals, setIsLoadingSignals]);
  useReachedBottom(BOTTOM_OFFSET, handleReachedBottom);

  // load first signals page
  useEffect(() => {
    if (!shouldFetch) return;

    let ignore = false;

    const loadNewSignals = async () => {
      await loadSignals();

      if (!ignore) {
        setIsLoadingSignals(false);
      }
    };

    loadNewSignals();

    return () => (ignore = true);
  }, [shouldFetch, loadSignals, symbols, dispatch]);

  return { isLoadingSignals, setIsLoadingSignals, loadSignals };
};

export default Signals;
