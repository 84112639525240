import humps from 'humps';
import useWebSocket from 'react-use-websocket';
import { useState, useMemo, useRef, useEffect } from 'react';

import useNotificationAudio from './useNotificationAudio';

const useRealTimeInfo = ({ channel, onMessageReceived, playSound = true }) => {
  const [welcomeReceived, setWelcomeReceived] = useState(false);

  const { play } = useNotificationAudio();
  const playRef = useRef(play);
  playRef.current = play;

  const options = useMemo(
    () => ({
      share: false,
      onMessage: message => {
        try {
          const data = humps.camelizeKeys(JSON.parse(message.data));

          if (data.type === 'welcome') {
            setWelcomeReceived(true);
          } else if (data.message && data.message.content) {
            onMessageReceived(data.message.content);
            playSound && playRef.current();
          }
        } catch {}
      },
      shouldReconnect: () => true,
    }),
    [onMessageReceived, playSound],
  );
  const [sendMessage] = useWebSocket(
    process.env.REACT_APP_WEB_SOCKET_URL,
    options,
  );

  useEffect(() => {
    if (welcomeReceived) {
      sendMessage(
        JSON.stringify({
          command: 'subscribe',
          identifier: JSON.stringify({
            channel,
          }),
        }),
      );
    }
  }, [channel, welcomeReceived, sendMessage]);
};

export default useRealTimeInfo;
