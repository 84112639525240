import { useState, useEffect, useCallback } from 'react';

const useAudio = url => {
  const [audio] = useState(new Audio(url));

  const play = useCallback(() => audio.play(), [audio]);
  const stop = useCallback(() => audio.pause(), [audio]);

  useEffect(() => {
    audio.addEventListener('ended', stop);
    return () => {
      audio.removeEventListener('ended', () => stop);
    };
  }, [audio, stop]);

  return { play, stop };
};

export default useAudio;
