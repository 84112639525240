import React, { useCallback } from 'react';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import { useSelector, useDispatch } from 'react-redux';

import styles from './styles.module.css';

import { setSoundEnabled } from 'actions/ui';

const SoundButton = () => {
  const dispatch = useDispatch();
  const soundEnabled = useSelector(state => state.ui.sound.enabled);

  const handleSoundStateChange = useCallback(
    () => dispatch(setSoundEnabled(!soundEnabled)),
    [dispatch, soundEnabled],
  );

  return (
    <div className={styles.soundButton} onClick={handleSoundStateChange}>
      {soundEnabled ? <NotificationsIcon /> : <NotificationsOffIcon />}
    </div>
  );
};

export default SoundButton;
