import SignalsController from 'controllers/SignalsController';

const NUMBER_OF_SIGNALS_PER_PAGE = 20;

export const actionTypes = {
  FETCH_SIGNALS: 'FETCH_SIGNALS',
  ADD_REALTIME_SIGNAL: 'ADD_REALTIME_SIGNAL',
};

const fetchSignalsSuccess = ({ signals, page, hasMoreSignals }) => ({
  type: actionTypes.FETCH_SIGNALS,
  signals,
  page,
  hasMoreSignals,
});

export const fetchSignals = (
  page = null,
  numberPerPage = NUMBER_OF_SIGNALS_PER_PAGE,
) => async (dispatch, getState) => {
  const symbols = getState().symbols.selectedSymbols;
  const queryPage = page || getState().signals.query.page;

  const signals = await SignalsController.fetchSignals(
    queryPage,
    numberPerPage,
    symbols
  );

  const hasMoreSignals = signals.length === numberPerPage;
  const newPage = hasMoreSignals ? queryPage + 1 : queryPage;

  dispatch(fetchSignalsSuccess({ signals, page: newPage, hasMoreSignals }));
};

export const addRealtimeSignal = ({ signal }) => ({
  type: actionTypes.ADD_REALTIME_SIGNAL,
  signal: SignalsController.parseItem(signal),
});
