import createReducer from 'utils/createReducer';
import { actionTypes } from 'actions/stocks';
import { actionTypes as symbolsActionTypes } from 'actions/symbols';

const initialState = {
  realTime: [],
  settings: {
    minPrice: 100,
    maxPrice: 100000,
  },
  priceRange: {
    minPrice: 100,
    maxPrice: 100000,
  },
  tradeKind: null,
  tradeSentiment: null,
  query: {
    page: 1,
    hasMoreStocks: true,
    stocks: [],
  },
};

const fecthSettingsSuccess = (state, action) => ({
  ...state,
  settings: action.settings,
  priceRange: action.settings,
});

const fetchStocksSuccess = (state, action) => ({
  ...state,
  query: {
    stocks: [...state.query.stocks, ...action.stocks],
    page: action.page,
    hasMoreStocks: action.hasMoreStocks,
  },
});

const addRealtimeStock = (state, action) => ({
  ...state,
  realTime: [action.stock, ...state.realTime],
});

const setSelectedSymbols = (state, action) => ({
  ...state,
  query: initialState.query,
});

const changePriceRange = (state, action) => ({
  ...state,
  query: initialState.query,
  priceRange: {
    ...state.priceRange,
    minPrice: action.minPrice,
    maxPrice: action.maxPrice,
  },
});

const setTradeKind = (state, action) => ({
  ...state,
  query: initialState.query,
  tradeKind: action.tradeKind,
});

const setTradeSentiment = (state, action) => ({
  ...state,
  query: initialState.query,
  tradeSentiment: action.tradeSentiment,
});

const handlers = {
  [actionTypes.FETCH_SETTINGS]: fecthSettingsSuccess,
  [actionTypes.FETCH_STOCKS]: fetchStocksSuccess,
  [actionTypes.ADD_REALTIME_STOCK]: addRealtimeStock,
  [actionTypes.CHANGE_PRICE_RANGE]: changePriceRange,
  [actionTypes.SET_TRADE_KIND]: setTradeKind,
  [actionTypes.SET_SENTIMENT_KIND]: setTradeSentiment,
  [symbolsActionTypes.SET_SELECTED_SYMBOLS]: setSelectedSymbols,
};

export default createReducer(initialState, handlers);
