import SymbolsController from 'controllers/SymbolsController';

export const actionTypes = {
  FETCH_SYMBOLS: 'FETCH_SYMBOLS',
  SET_SELECTED_SYMBOLS: 'SET_SELECTED_SYMBOLS',
};

const fetchSymbolsSuccess = symbols => ({
  type: actionTypes.FETCH_SYMBOLS,
  symbols,
});

export const fetchStockSymbols = () => async dispatch => {
  const symbols = await SymbolsController.fetchStockSymbols();
  dispatch(fetchSymbolsSuccess(symbols));
};

export const fetchSignalsSymbols = () => async dispatch => {
  const symbols = await SymbolsController.fetchSignalsSymbols();
  dispatch(fetchSymbolsSuccess(symbols));
};

export const fetchNewsSymbols = () => async dispatch => {
  const symbols = await SymbolsController.fetchNewsSymbols();
  dispatch(fetchSymbolsSuccess(symbols));
};

export const setSelectedSymbols = symbols => ({
  type: actionTypes.SET_SELECTED_SYMBOLS,
  symbols,
});
