import { combineReducers } from 'redux';

import news from './newsReducer';
import signals from './signalsReducer';
import stocks from './stocksReducer';
import symbols from './symbolsReducer';
import ui from './uiReducer';
import wiim from './wiimReducer';

const rootReducer = combineReducers({
  news,
  signals,
  stocks,
  symbols,
  ui,
  wiim,
});

export default rootReducer;
