import NewsController from 'controllers/NewsController';

const NUMBER_OF_NEWS_PER_PAGE = 20;

export const actionTypes = {
  FETCH_NEWS: 'FETCH_NEWS',
  FETCH_WIIM: 'FETCH_WIIM',
  ADD_REALTIME_NEWS: 'ADD_REALTIME_NEWS',
  ADD_REALTIME_WIIM: 'ADD_REALTIME_WIIM',
};

const fetchNewsSuccess = ({ news, page, hasMoreNews }) => ({
  type: actionTypes.FETCH_NEWS,
  news,
  page,
  hasMoreNews,
});

export const fetchNews = (
  page = null,
  numberPerPage = NUMBER_OF_NEWS_PER_PAGE,
) => async (dispatch, getState) => {
  const symbols = getState().symbols.selectedSymbols;
  const queryPage = page || getState().news.query.page;

  const news = await NewsController.fetchNews(
    queryPage,
    numberPerPage,
    symbols,
  );

  const hasMoreNews = news.length === numberPerPage;
  const newPage = hasMoreNews ? queryPage + 1 : queryPage;

  dispatch(fetchNewsSuccess({ news, page: newPage, hasMoreNews }));
};

export const addRealtimeNews = ({ news }) => ({
  type: actionTypes.ADD_REALTIME_NEWS,
  news: NewsController.parseItem(news),
});

const fetchWiimSuccess = ({ wiim, page, hasMoreWiim }) => ({
  type: actionTypes.FETCH_WIIM,
  wiim,
  page,
  hasMoreWiim,
});

export const fetchWiim = (
  page = null,
  numberPerPage = NUMBER_OF_NEWS_PER_PAGE,
) => async (dispatch, getState) => {
  const symbols = getState().symbols.selectedSymbols;
  const queryPage = page || getState().wiim.query.page;

  const wiim = await NewsController.fetchWhyIsItMoving(
    queryPage,
    numberPerPage,
    symbols,
  );

  const hasMoreWiim = wiim.length === numberPerPage;
  const newPage = hasMoreWiim ? queryPage + 1 : queryPage;

  dispatch(fetchWiimSuccess({ wiim, page: newPage, hasMoreWiim }));
};

export const addRealtimeWiim = ({ wiim }) => ({
  type: actionTypes.ADD_REALTIME_WIIM,
  wiim: NewsController.parseItem(wiim),
});
