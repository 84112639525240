import { v4 as uuidv4 } from 'uuid';

import client from 'api/httpClient';

class StockController {
  constructor() {
    this.baseUrl = '/scanner_triggers';
    this.settingsUrl = '/settings/scanner_triggers';
  }

  parseItem = item => ({
    ...item,
    id: uuidv4(),
  });

  fetchStocks = async (
    symbols,
    page,
    numberPerPage,
    settings,
    tradeKind,
    tradeSentiment,
  ) => {
    const { minPrice, maxPrice } = settings;

    try {
      const { data } = await client.get(this.baseUrl, {
        params: {
          page,
          per_page: numberPerPage,
          symbols: symbols.join(),
          min_price: minPrice,
          max_price: maxPrice,
          trade_kind: tradeKind,
          trade_sentiment: tradeSentiment,
        },
      });

      return data.map(this.parseItem);
    } catch {
      return [];
    }
  };

  fetchSettings = async () => {
    try {
      const { data } = await client.get(this.settingsUrl);
      return data;
    } catch {
      return {
        minPrice: 3000,
        maxPrice: 40000,
      };
    }
  };
}

export default new StockController();
