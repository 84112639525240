import StockController from 'controllers/StockController';

const NUMBER_OF_STOCKS_PER_PAGE = 20;

export const actionTypes = {
  FETCH_SETTINGS: 'FETCH_SETTINGS',
  FETCH_STOCKS: 'FETCH_STOCKS',
  CHANGE_PRICE_RANGE: 'CHANGE_PRICE_RANGE',
  ADD_REALTIME_STOCK: 'ADD_REALTIME_STOCK',
  SET_TRADE_KIND: 'SET_TRADE_KIND',
  SET_SENTIMENT_KIND: 'SET_SENTIMENT_KIND',
};

const fetchSettingsSuccess = settings => ({
  type: actionTypes.FETCH_SETTINGS,
  settings,
});

export const fetchSettings = () => async dispatch => {
  const settings = await StockController.fetchSettings();
  dispatch(fetchSettingsSuccess(settings));
};

const fetchStocksSuccess = ({ stocks, page, hasMoreStocks }) => ({
  type: actionTypes.FETCH_STOCKS,
  stocks,
  page,
  hasMoreStocks,
});

export const fetchStocks = (
  symbols = [],
  page = null,
  numberPerPage = NUMBER_OF_STOCKS_PER_PAGE,
) => async (dispatch, getState) => {
  const queryPage = page || getState().stocks.query.page;
  const settings = getState().stocks.priceRange;
  const { tradeKind, tradeSentiment } = getState().stocks;

  const stocks = await StockController.fetchStocks(
    symbols,
    queryPage,
    numberPerPage,
    settings,
    tradeKind,
    tradeSentiment,
  );

  const hasMoreStocks = stocks.length === numberPerPage;
  const newPage = hasMoreStocks ? queryPage + 1 : queryPage;

  dispatch(fetchStocksSuccess({ stocks, page: newPage, hasMoreStocks }));
};

export const addRealtimeStock = ({ stock }) => ({
  type: actionTypes.ADD_REALTIME_STOCK,
  stock: StockController.parseItem(stock),
});

export const changePriceRange = ({ minPrice, maxPrice }) => ({
  type: actionTypes.CHANGE_PRICE_RANGE,
  minPrice,
  maxPrice,
});

export const setTradeKind = tradeKind => ({
  type: actionTypes.SET_TRADE_KIND,
  tradeKind,
});

export const setTradeSentiment = tradeSentiment => ({
  type: actionTypes.SET_SENTIMENT_KIND,
  tradeSentiment,
});

export const filterRealtimeSignals = stocks => (_, getState) => {
  const {
    stocks: {
      tradeKind,
      priceRange: { minPrice, maxPrice },
      tradeSentiment,
    },
    symbols: { selectedSymbols },
  } = getState();

  return stocks.filter(stock => {
    if (tradeKind && tradeKind !== stock.tradeKind) return false;
    if (tradeSentiment && tradeSentiment !== stock.tradeSentiment) return false;

    if (
      stock.stock &&
      (stock.stock.price < minPrice || stock.stock.price > maxPrice)
    )
      return false;
    return (
      !selectedSymbols ||
      !selectedSymbols.length ||
      selectedSymbols.includes(stock.stock.symbol)
    );
  });
};
