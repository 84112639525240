import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import InputRange from 'react-input-range';

import 'react-input-range/lib/css/index.css';
import styles from './styles.module.css';

import { changePriceRange } from 'actions/stocks';

const PriceInputRange = () => {
  const dispatch = useDispatch();

  const { minPrice, maxPrice } = useSelector(state => state.stocks.settings);
  const { minPrice: currentMinPrice, maxPrice: currentMaxPrice } = useSelector(
    state => state.stocks.priceRange,
  );

  const [value, setValue] = useState({
    min: currentMinPrice / 100,
    max: currentMaxPrice / 100,
  });

  const handleFormatLabel = useCallback(value => `$${value}`, []);
  const handleChange = useCallback(
    value =>
      setValue({
        min: Math.max(minPrice / 100, value.min),
        max: Math.min(maxPrice / 100, value.max),
      }),
    [minPrice, maxPrice],
  );
  const handleChangeComplete = useCallback(
    value => {
      dispatch(
        changePriceRange({
          minPrice: value.min * 100,
          maxPrice: value.max * 100,
        }),
      );
    },
    [dispatch],
  );

  return (
    <div className={styles.inputRangeContainer}>
      <InputRange
        minValue={minPrice / 100}
        maxValue={maxPrice / 100}
        formatLabel={handleFormatLabel}
        value={value}
        onChange={handleChange}
        onChangeComplete={handleChangeComplete}
      />
    </div>
  );
};

export default PriceInputRange;
