import React, { useCallback } from 'react';

const Image = ({ fallbackUrl, ...props }) => {
  const handleError = useCallback(
    event => {
      event.target.src = fallbackUrl;
    },
    [fallbackUrl],
  );

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img {...props} onError={handleError} />
  );
};

export default Image;
