import React from 'react';
import clsx from 'clsx';

import styles from './styles.module.css';

const Table = ({
  className,
  columnClasses = {},
  items,
  columns,
  renderCell,
  cellClick
}) => {
  if (!items || !items.length || !columns || !columns.length) return null;

  return (
    <table className={clsx(styles.table, className)}>
      <thead className={styles.head}>
        <tr className={styles.row}>
          {columns.map((column, index) => (
            <th
              key={index}
              className={columnClasses[column.key] || styles.cell}
            >
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={styles.body}>
        {items.map(item => (
          <tr key={item.id} className={styles.row}>
            {columns.map((column, index) => (
              <td
                key={index}
                className={columnClasses[column.key] || styles.cell}
                onClick={() => cellClick(column, item)}
              >
                {renderCell(column.key, item)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
