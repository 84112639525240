import React, { useCallback, useMemo, useEffect } from 'react';
import ClearIcon from '@material-ui/icons/Clear';
import ReactTags from 'react-tag-autocomplete';
import { useDispatch, useSelector } from 'react-redux';

import styles from './styles.module.css';

import { setSelectedSymbols } from 'actions/symbols';

const MIN_QUERY_LENGTH = 1;
const MAX_SUGGESTIONS_LENGTH = 1000;

const Tag = ({ classNames, tag, onDelete }) => (
  <div className={classNames.selectedTag} title="Click to remove symbol">
    <span className={classNames.selectedTagName}>{tag.name}</span>
    <span className={styles.tagInput__selectedTag__remove} onClick={onDelete}>
      <ClearIcon />
    </span>
  </div>
);

const SymbolTagInput = props => {
  const { url } = props;
  const dispatch = useDispatch();

  const symbols = useSelector(state => state.symbols.symbols);
  const selectedSymbols = useSelector(state => state.symbols.selectedSymbols);

  useEffect(() => {
    const symbols = localStorage.getItem(`tradeStocks-${url}`);
    if (symbols !== null) {
      dispatch(setSelectedSymbols(JSON.parse(symbols)));
    }
  }, [dispatch, url]);

  const handleDelete = useCallback(
    index => {
      const symbols = selectedSymbols.filter(
        (_, currentIndex) => currentIndex !== index,
      );
      dispatch(setSelectedSymbols(symbols));
      localStorage.setItem(`tradeStocks-${url}`, JSON.stringify(symbols));
    },
    [selectedSymbols, dispatch, url],
  );

  const handleAddition = useCallback(
    tag => {
      const symbols = [...selectedSymbols, tag.name];
      dispatch(setSelectedSymbols(symbols));
      localStorage.setItem(`tradeStocks-${url}`, JSON.stringify(symbols));
    },
    [dispatch, selectedSymbols, url],
  );

  const tags = useMemo(
    () =>
      selectedSymbols.map(symbol => ({
        id: symbol,
        name: symbol,
      })),
    [selectedSymbols],
  );

  const suggestions = useMemo(
    () =>
      symbols
        .filter(symbol => !selectedSymbols.includes(symbol))
        .map(symbol => ({
          id: symbol,
          name: symbol,
        })),
    [symbols, selectedSymbols],
  );

  return (
    <ReactTags
      placeholder="Filter by symbol"
      tags={tags}
      suggestions={suggestions}
      handleAddition={handleAddition}
      handleDelete={handleDelete}
      minQueryLength={MIN_QUERY_LENGTH}
      maxSuggestionsLength={MAX_SUGGESTIONS_LENGTH}
      autoresize={false}
      autofocus={false}
      tagComponent={Tag}
      classNames={{
        root: styles.tagInput,
        rootFocused: styles.isFocused,
        selected: styles.tagInput__selected,
        selectedTag: styles.tagInput__selectedTag,
        selectedTagName: '',
        search: styles.tagInput__search,
        searchInput: styles.tagInput__search__input,
        suggestions: styles.tagInput__suggestions,
        suggestionActive: styles.isActive,
        suggestionDisabled: styles.isDisabled,
      }}
    />
  );
};

export default SymbolTagInput;
