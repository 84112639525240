import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Wiim.module.css';

import Header from 'components/Header';
import NewsItem from 'components/WiimItem';
import SoundButton from 'components/SoundButton';
import SymbolTagInput from 'components/SymbolTagInput';
import useReachedBottom from 'hooks/useReachedBottom';
import useRealtimeInfo from 'hooks/useRealtimeInfo';
import { fetchNewsSymbols } from 'actions/symbols';
import { fetchWiim, addRealtimeWiim } from 'actions/news';

const LOGO_URL =
  'https://www.transparenttradersblackbox.com/images/cube_icons/catalyst-cube.png';
const CHANNEL_NAME = 'NewsChannel';
const BOTTOM_OFFSET = 100;

const Wiim = () => {
  const dispatch = useDispatch();

  const symbols = useSelector(state => state.symbols.selectedSymbols);
  const queryWiim = useSelector(state => state.wiim.query.wiim);
  const realTimeWiim = useSelector(state => state.wiim.realTime);

  const { isLoadingSymbols } = useLoadSymbols();
  useLoadWiim(!isLoadingSymbols);

  const onMessageReceived = useCallback(
    message => {
      if (!message || !message.json || !message.json.isWiim) return;
      dispatch(addRealtimeWiim({ wiim: message.json }));
    },
    [dispatch],
  );
  useRealtimeInfo({
    channel: CHANNEL_NAME,
    onMessageReceived,
  });

  const wiim = useMemo(() => {
    const filteredRealTimeWiim =
      !symbols || !symbols.length
        ? realTimeWiim
        : realTimeWiim.filter(entry =>
            entry?.stocks?.some(stock => symbols.includes(stock?.name ?? '')),
          );
    return [...filteredRealTimeWiim, ...queryWiim];
  }, [symbols, realTimeWiim, queryWiim]);

  return (
    <div className={styles.container}>
      <Header logoUrl={LOGO_URL} title="Why is it moving - Catalyst" />
      <div className={styles.content}>
        <div className={styles.optionsContainer}>
          <SymbolTagInput url="wiim" />
          <SoundButton />
        </div>
        <div className={styles.wiimContainer}>
          {wiim.map(currentWiim => (
            <NewsItem key={currentWiim.id} news={currentWiim} />
          ))}
        </div>
      </div>
    </div>
  );
};

const useLoadSymbols = () => {
  const dispatch = useDispatch();
  const [isLoadingSymbols, setIsLoadingSymbols] = useState(true);

  useEffect(() => {
    let ignore = false;

    const loadSymbols = async () => {
      setIsLoadingSymbols(true);

      await dispatch(fetchNewsSymbols());

      if (!ignore) {
        setIsLoadingSymbols(false);
      }
    };

    loadSymbols();

    return () => (ignore = true);
  }, [dispatch]);

  return { isLoadingSymbols };
};

const useLoadWiim = shouldFetch => {
  const dispatch = useDispatch();
  const [isLoadingWiim, setIsLoadingWiim] = useState(true);

  const symbols = useSelector(state => state.symbols.selectedSymbols);
  const hasMoreWiim = useSelector(state => state.wiim.query.hasMoreWiim);

  const loadWiim = useCallback(async () => {
    if (!hasMoreWiim) return;
    setIsLoadingWiim(true);
    await dispatch(fetchWiim());
  }, [hasMoreWiim, dispatch]);

  // load next wiim page when reached bottom
  const handleReachedBottom = useCallback(async () => {
    if (isLoadingWiim) return;
    await loadWiim();
    setIsLoadingWiim(false);
  }, [isLoadingWiim, loadWiim]);
  useReachedBottom(BOTTOM_OFFSET, handleReachedBottom);

  // load first wiim page
  useEffect(() => {
    if (!shouldFetch) return;

    let ignore = false;

    const loadFirstWiim = async () => {
      await loadWiim();

      if (!ignore) {
        setIsLoadingWiim(false);
      }
    };

    loadFirstWiim();

    return () => (ignore = true);
  }, [loadWiim, shouldFetch, symbols, dispatch]);

  return { isLoadingWiim, setIsLoadingWiim, loadWiim };
};

export default Wiim;
