import React from 'react';
import dayjs from 'dayjs';

import styles from './styles.module.css';

import Image from 'components/Image';
import { removeDuplicates } from 'utils/array';

const logo =
  'https://www.transparenttradersblackbox.com/images/cube_icons/news-cube.png';

const DATE_FORMAT = 'YYYY MMM DD, h:mm a';
const SENTIMENT = {
  BEARISH: styles.stockRed,
  BULLISH: styles.stockGreen,
};

const getImage = news =>
  news.image && news.image[0] && news.image[0].url ? news.image[0].url : logo;

const buildStockInfo = news => {
  const stocks = removeDuplicates(
    (news?.stocks || []).filter(stock => !!stock.name),
    'name',
  );

  return stocks.map((stock, index) => {
    const lastStock = index === stocks.length - 1;

    let sentiment = null;
    if (news?.stockInfo?.symbol === stock.name) {
      sentiment = news?.stockInfo?.lastBenzingaSignal?.sentiment;
    }

    const sentimentClass = SENTIMENT[sentiment];
    return sentimentClass ? (
      <span key={index}>
        <span className={sentimentClass}>{stock.name}</span>
        {!lastStock && <>, </>}
      </span>
    ) : (
      <span key={index}>
        {stock.name}
        {!lastStock && <>, </>}
      </span>
    );
  });
};

const NewsItem = ({ news }) => {
  const date = dayjs(news.timestamp).format(DATE_FORMAT);
  const stocks = buildStockInfo(news);
  const avatar = getImage(news);

  return (
    <div className={styles.container}>
      <Image
        fallbackUrl={logo}
        src={avatar}
        className={styles.avatar}
        alt="News Content"
      />
      <div className={styles.contentContainer}>
        {stocks && <div className={styles.stocksContainer}>{stocks}</div>}
        {news.title && <div className={styles.title}>{news.title}</div>}
        {date && <div className={styles.dateContainer}>{date}</div>}
        <a
          href={news.url}
          rel="noopener noreferrer"
          target="_blank"
          className={styles.button}
        >
          Read more
        </a>
      </div>
    </div>
  );
};

export default NewsItem;
