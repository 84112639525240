import createReducer from 'utils/createReducer';
import { actionTypes } from 'actions/ui';

const initialState = {
  sound: {
    enabled: false,
    lastPlayed: null,
  },
};

const setSoundEnabled = (state, action) => ({
  ...state,
  sound: {
    ...state.sound,
    enabled: action.enabled,
  },
});

const setSoundLastPlayed = (state, action) => ({
  ...state,
  sound: {
    ...state.sound,
    lastPlayed: action.lastPlayed,
  },
});

const handlers = {
  [actionTypes.SET_SOUND_ENABLED]: setSoundEnabled,
  [actionTypes.SET_SOUND_LAST_PLAYED]: setSoundLastPlayed,
};

export default createReducer(initialState, handlers);
