import createReducer from 'utils/createReducer';
import { actionTypes } from 'actions/news';
import { actionTypes as symbolsActionTypes } from 'actions/symbols';

const initialState = {
  realTime: [],
  query: {
    page: 1,
    hasMoreNews: true,
    news: [],
  },
};

const fetchNewsSuccess = (state, action) => ({
  ...state,
  query: {
    news: [...state.query.news, ...action.news],
    page: action.page,
    hasMoreNews: action.hasMoreNews,
  },
});

const addRealtimeNews = (state, action) => ({
  ...state,
  realTime: [action.news, ...state.realTime],
});

const setSelectedSymbols = state => ({
  ...state,
  query: initialState.query,
});

const handlers = {
  [actionTypes.FETCH_NEWS]: fetchNewsSuccess,
  [actionTypes.ADD_REALTIME_NEWS]: addRealtimeNews,
  [symbolsActionTypes.SET_SELECTED_SYMBOLS]: setSelectedSymbols,
};

export default createReducer(initialState, handlers);
