import React, { useRef, useLayoutEffect } from 'react';

import logo from 'assets/logo.jpg';
import styles from './styles.module.css';

import SoundButton from 'components/SoundButton';

const Header = ({ title, logoUrl, showSoundButton = false }) => {
  const headerRef = useRef();

  useLayoutEffect(() => {
    const handleScroll = () => {
      const position = Math.min(
        headerRef.current.offsetHeight,
        document.documentElement.scrollTop,
      );
      const percentage =
        Math.round((position * 100) / headerRef.current.offsetHeight) / 100;
      const scale = 0.5 + 0.5 * (1 - percentage);
      const buttonScale = scale + 1.2 * percentage;

      headerRef.current.style.transform = `scaleY(${scale})`;
      headerRef.current.firstElementChild.style.transform = `scaleX(${scale})`;
      if (
        headerRef.current.firstElementChild !==
        headerRef.current.lastElementChild
      ) {
        headerRef.current.lastElementChild.style.transform = `scaleY(${buttonScale})`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <div className={styles.headerContainer} ref={headerRef}>
      <div className={styles.header}>
        <img src={logoUrl || logo} className={styles.logo} alt="Transparent Traders" />
        <h1 className={styles.title}>{title}</h1>
      </div>
      {showSoundButton && (
        <div className={styles.soundButton}>
          <SoundButton />
        </div>
      )}
    </div>
  );
};

export default Header;
