export const actionTypes = {
  SET_SOUND_ENABLED: 'SET_SOUND_ENABLED',
  SET_SOUND_LAST_PLAYED: 'SET_SOUND_LAST_PLAYED',
};

export const setSoundEnabled = enabled => ({
  type: actionTypes.SET_SOUND_ENABLED,
  enabled,
});

export const setSoundLastPlayed = lastPlayed => ({
  type: actionTypes.SET_SOUND_LAST_PLAYED,
  lastPlayed,
});
