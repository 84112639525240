import createReducer from 'utils/createReducer';
import { actionTypes } from 'actions/news';
import { actionTypes as symbolsActionTypes } from 'actions/symbols';

const initialState = {
  realTime: [],
  query: {
    page: 1,
    hasMoreWiim: true,
    wiim: [],
  },
};

const fetchWiimSuccess = (state, action) => ({
  ...state,
  query: {
    wiim: [...state.query.wiim, ...action.wiim],
    page: action.page,
    hasMoreWiim: action.hasMoreWiim,
  },
});

const addRealtimeWiim = (state, action) => ({
  ...state,
  realTime: [action.wiim, ...state.realTime],
});

const setSelectedSymbols = state => ({
  ...state,
  query: initialState.query,
});

const handlers = {
  [actionTypes.FETCH_WIIM]: fetchWiimSuccess,
  [actionTypes.ADD_REALTIME_WIIM]: addRealtimeWiim,
  [symbolsActionTypes.SET_SELECTED_SYMBOLS]: setSelectedSymbols,
};

export default createReducer(initialState, handlers);
