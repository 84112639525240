import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import './styles.module.css';

import News from './news';
import Screener from './screener';
import Signals from './signals';
import Wiim from './wiim';
import * as routes from 'constants/routes';

const App = () => (
  <Router>
    <Switch>
      <Route path={routes.SCREENER_PATH}>
        <Screener />
      </Route>
      <Route path={routes.SIGNALS_PATH}>
        <Signals />
      </Route>
      <Route path={routes.NEWS_PATH}>
        <News />
      </Route>
      <Route path={routes.WIIM_PATH}>
        <Wiim />
      </Route>
      <Route path="*">
        <Redirect to={routes.SCREENER_PATH} />
      </Route>
    </Switch>
  </Router>
);

export default App;
