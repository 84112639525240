import React, { useEffect, useState, useCallback } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedSymbols } from 'actions/symbols';

import styles from './Screener.module.css';

import Header from 'components/Header';
import PriceInputRange from 'components/PriceInputRange';
import SoundButton from 'components/SoundButton';
import SymbolTagInput from 'components/SymbolTagInput';
import Table from 'components/Table';
import useReachedBottom from 'hooks/useReachedBottom';
import useRealtimeInfo from 'hooks/useRealtimeInfo';
import { fetchStockSymbols } from 'actions/symbols';
import {
  fetchSettings,
  fetchStocks,
  addRealtimeStock,
  setTradeKind,
  filterRealtimeSignals,
  setTradeSentiment,
} from 'actions/stocks';

const LOGO_URL =
  'https://www.transparenttradersblackbox.com/images/cube_icons/ttbb-cube.png';
const BOTTOM_OFFSET = 100;
const CHANNEL_NAME = 'ScannerTriggersChannel';
const DATE_FORMAT = 'MMM DD, YYYY HH:mm';
const COLUMNS = [
  { label: 'Timestamp', key: 'timestamp' },
  { label: 'Kind', key: 'kind' },
  { label: 'Stock', key: 'stock' },
  { label: 'Stock Name', key: 'stockName' },
  { label: 'Scale In Ready?', key: 'active' },
];
const SENTIMENT = {
  [styles.tableStockRed]: ['bearish_swing_trade', 'bearish_5_minute_movers'],
  [styles.tableStockGreen]: ['bullish_swing_trade', 'bullish_5_minute_movers'],
};
const TRADE_SENTIMENT = {
  bullish: { kind: 'bullish', label: 'Bullish' },
  bearish: { kind: 'bearish', label: 'Bearish' },
};
const CURRENT_URL = 'screener';

export const TRADE_KIND = {
  swing: { kind: 'swing', label: 'Swing trades' },
  day: { kind: 'day', label: 'Day trades' },
};

const useRenderCell = (columnKey, item) => {
  const renderer = {
    timestamp: () => dayjs(item.periodReference).format(DATE_FORMAT),
    kind: () => item.humanizedKind,
    stock: () => {
      const sentimentClass = Object.keys(SENTIMENT).find(key =>
        SENTIMENT[key].includes(item.kind),
      );
      return (
        <strong>
          {sentimentClass ? (
            <span className={sentimentClass}>{item.stock.symbol}</span>
          ) : (
            <>{item.stock.symbol}</>
          )}
        </strong>
      );
    },
    stockName: () => item.stock.name,
    active: () => {
      if (item.triggered == null) return null;
      const className = item.triggered
        ? styles.tableTriggeredGreen
        : styles.tableTriggeredRed;
      return <span className={`${styles.tableTriggered} ${className}`} />;
    },
  };

  return renderer[columnKey]();
};

const Screener = () => {
  const dispatch = useDispatch();
  const selectedSymbols = useSelector(state => state.symbols.selectedSymbols);
  const queryStocks = useSelector(state => state.stocks.query.stocks);
  const hasMoreStocks = useSelector(state => state.stocks.query.hasMoreStocks);
  const realTimeStocks = useSelector(state => state.stocks.realTime);
  const tradeKind = useSelector(state => state.stocks.tradeKind);
  const tradeSentiment = useSelector(state => state.stocks.tradeSentiment);

  const { isLoadingSymbols } = useLoadSymbols();
  const { isLoadingStocks, setIsLoadingStocks, loadStocks } = useLoadStocks(
    !isLoadingSymbols,
  );

  const handleReachedBottom = useCallback(async () => {
    if (isLoadingSymbols || isLoadingStocks || !hasMoreStocks) return;
    await loadStocks();
    setIsLoadingStocks(false);
  }, [
    isLoadingStocks,
    isLoadingSymbols,
    loadStocks,
    hasMoreStocks,
    setIsLoadingStocks,
  ]);
  useReachedBottom(BOTTOM_OFFSET, handleReachedBottom);

  const onMessageReceived = useCallback(
    message => {
      dispatch(addRealtimeStock({ stock: message }));
    },
    [dispatch],
  );
  useRealtimeInfo({
    channel: CHANNEL_NAME,
    onMessageReceived,
  });

  const handleTradeKindChange = useCallback(
    kind => dispatch(setTradeKind(tradeKind === kind ? null : kind)),
    [dispatch, tradeKind],
  );

  const handleTradeSentiment = useCallback(
    kind => dispatch(setTradeSentiment(tradeSentiment === kind ? null : kind)),
    [dispatch, tradeSentiment],
  );

  const stocks = [
    ...dispatch(filterRealtimeSignals(realTimeStocks)),
    ...queryStocks,
  ];

  const useHandleCellClick = async (column, tag) => {
    const { key } = column;
    const { stock } = tag;

    if (key === 'stock' && !selectedSymbols.includes(stock.symbol)) {
      const symbol = [stock.symbol];
      setIsLoadingStocks(true);
      await dispatch(setSelectedSymbols(symbol));
      localStorage.setItem(
        `tradeStocks-${CURRENT_URL}`,
        JSON.stringify(symbol),
      );
      setIsLoadingStocks(false);
    }
  };

  return (
    <div className={styles.container}>
      <Header logoUrl={LOGO_URL} title="Black Box" />
      <div className={styles.content}>
        <div className={styles.optionsContainer}>
          <SymbolTagInput url={CURRENT_URL} />
          <SoundButton />
        </div>
        <div className={styles.priceRangeContainer}>
          <span>Price range: </span>
          <PriceInputRange />
        </div>
        <div className={styles.tradeFilterContainer}>
          <span>Filter by trade:</span>
          <div className={styles.filters}>
            {Object.keys(TRADE_KIND).map(key => (
              <span
                key={key}
                className={clsx(styles.filter, {
                  [styles.filterSelected]: TRADE_KIND[key].kind === tradeKind,
                })}
                onClick={() => handleTradeKindChange(key)}
              >
                {TRADE_KIND[key].label}
              </span>
            ))}
          </div>
        </div>
        <div className={styles.tradeFilterContainer}>
          <span>Filter by sentiment:</span>
          <div className={styles.filters}>
            {Object.keys(TRADE_SENTIMENT).map(key => (
              <span
                key={key}
                className={clsx(styles.filter, {
                  [styles.filterSelected]:
                    TRADE_SENTIMENT[key].kind === tradeSentiment,
                })}
                onClick={() => handleTradeSentiment(key)}
              >
                {TRADE_SENTIMENT[key].label}
              </span>
            ))}
          </div>
        </div>
        <div className={styles.tableContainer}>
          <Table
            className={styles.table}
            columnClasses={{
              stock: styles.tableStockColumn,
              stockName: styles.tableStockNameColumn,
              active: styles.tableTriggeredColumn,
            }}
            items={stocks}
            columns={COLUMNS}
            renderCell={useRenderCell}
            cellClick={useHandleCellClick}
          />
        </div>
      </div>
    </div>
  );
};

const useLoadSymbols = () => {
  const dispatch = useDispatch();
  const [isLoadingSymbols, setIsLoadingSymbols] = useState(true);

  useEffect(() => {
    let ignore = false;

    const loadSymbols = async () => {
      setIsLoadingSymbols(true);

      await dispatch(fetchSettings());
      await dispatch(fetchStockSymbols());

      if (!ignore) {
        setIsLoadingSymbols(false);
      }
    };

    loadSymbols();

    return () => (ignore = true);
  }, [dispatch]);

  return { isLoadingSymbols };
};

const useLoadStocks = shouldFetch => {
  const dispatch = useDispatch();
  const [isLoadingStocks, setIsLoadingStocks] = useState(true);

  const symbols = useSelector(state => state.symbols.selectedSymbols);
  const priceRange = useSelector(state => state.stocks.priceRange);
  const tradeKind = useSelector(state => state.stocks.tradeKind);
  const tradeSentiment = useSelector(state => state.stocks.tradeSentiment);

  const loadStocks = useCallback(async () => {
    setIsLoadingStocks(true);
    await dispatch(fetchStocks(symbols));
  }, [symbols, dispatch]);

  useEffect(() => {
    if (!shouldFetch) return;

    let ignore = false;

    const loadNewStocks = async () => {
      await loadStocks();

      if (!ignore) {
        setIsLoadingStocks(false);
      }
    };

    loadNewStocks();

    return () => (ignore = true);
  }, [
    shouldFetch,
    loadStocks,
    priceRange,
    tradeKind,
    tradeSentiment,
    dispatch,
  ]);

  return { isLoadingStocks, setIsLoadingStocks, loadStocks };
};

export default Screener;
