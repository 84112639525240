import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './News.module.css';

import Header from 'components/Header';
import NewsItem from 'components/NewsItem';
import SoundButton from 'components/SoundButton';
import SymbolTagInput from 'components/SymbolTagInput';
import useReachedBottom from 'hooks/useReachedBottom';
import useRealtimeInfo from 'hooks/useRealtimeInfo';
import { fetchNewsSymbols } from 'actions/symbols';
import { fetchNews, addRealtimeNews } from 'actions/news';

const LOGO_URL =
  'https://www.transparenttradersblackbox.com/images/cube_icons/news-cube.png';
const CHANNEL_NAME = 'NewsChannel';
const BOTTOM_OFFSET = 100;

const News = () => {
  const dispatch = useDispatch();

  const symbols = useSelector(state => state.symbols.selectedSymbols);
  const queryNews = useSelector(state => state.news.query.news);
  const realTimeNews = useSelector(state => state.news.realTime);

  const { isLoadingSymbols } = useLoadSymbols();
  useLoadNews(!isLoadingSymbols);

  const onMessageReceived = useCallback(
    message => {
      dispatch(addRealtimeNews({ news: message.json }));
    },
    [dispatch],
  );
  useRealtimeInfo({
    channel: CHANNEL_NAME,
    onMessageReceived,
  });

  const news = useMemo(() => {
    const filteredRealTimeNews =
      !symbols || !symbols.length
        ? realTimeNews
        : realTimeNews.filter(entry =>
            entry?.stocks?.some(stock => symbols.includes(stock?.name ?? '')),
          );
    return [...filteredRealTimeNews, ...queryNews];
  }, [symbols, realTimeNews, queryNews]);

  return (
    <div className={styles.container}>
      <Header logoUrl={LOGO_URL} title="Breaking News" />
      <div className={styles.content}>
        <div className={styles.optionsContainer}>
          <SymbolTagInput url="news" />
          <SoundButton />
        </div>
        <div className={styles.newsContainer}>
          {news.map(currentNews => (
            <NewsItem key={currentNews.id} news={currentNews} />
          ))}
        </div>
      </div>
    </div>
  );
};

const useLoadSymbols = () => {
  const dispatch = useDispatch();
  const [isLoadingSymbols, setIsLoadingSymbols] = useState(true);

  useEffect(() => {
    let ignore = false;

    const loadSymbols = async () => {
      setIsLoadingSymbols(true);

      await dispatch(fetchNewsSymbols());

      if (!ignore) {
        setIsLoadingSymbols(false);
      }
    };

    loadSymbols();

    return () => (ignore = true);
  }, [dispatch]);

  return { isLoadingSymbols };
};

const useLoadNews = shouldFetch => {
  const dispatch = useDispatch();
  const [isLoadingNews, setIsLoadingNews] = useState(true);

  const symbols = useSelector(state => state.symbols.selectedSymbols);
  const hasMoreNews = useSelector(state => state.news.query.hasMoreNews);

  const loadNews = useCallback(async () => {
    if (!hasMoreNews) return;
    setIsLoadingNews(true);
    await dispatch(fetchNews());
  }, [hasMoreNews, dispatch]);

  // load next news page when reached bottom
  const handleReachedBottom = useCallback(async () => {
    if (isLoadingNews) return;
    await loadNews();
    setIsLoadingNews(false);
  }, [isLoadingNews, loadNews, setIsLoadingNews]);
  useReachedBottom(BOTTOM_OFFSET, handleReachedBottom);

  // load first news page
  useEffect(() => {
    if (!shouldFetch) return;

    let ignore = false;

    const loadFirstNews = async () => {
      await loadNews();

      if (!ignore) {
        setIsLoadingNews(false);
      }
    };

    loadFirstNews();

    return () => (ignore = true);
  }, [loadNews, shouldFetch, symbols, dispatch]);

  return { isLoadingNews, setIsLoadingNews, loadNews };
};

export default News;
